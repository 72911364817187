<template>
  <div id="ReportScreen">
    <v-container>
      <v-row>
        <v-col class="col-12">
          <v-list subheader two-line>
            <v-list-item
              class="list"
              v-for="report in reports"
              :key="report.title"
            >
              <v-list-item-avatar>
                <v-icon class="primary" dark>mdi-clipboard-text</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="report.title"></v-list-item-title>

                <v-list-item-subtitle
                  v-text="report.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon :disabled="loadingStatus">
                  <v-icon color="primary" @click.stop="showDialog(report)"
                    >mdi-download</v-icon
                  >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Cetak {{ reportData.title }}</h5>
          </v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <v-form v-model="valid" ref="form" lazy-validation class="mt-5">
            <v-menu
              v-model="menuFromDate"
              :close-on-content-click="false"
              :nudge-top="20"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="fromDate"
                  label="Dari Tanggal"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                @input="menuFromDate = false"
                :min="reportData.id === '5' ? getStartDate : null"
                :max="reportData.id === '5' ? nowDate : null"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="menuToDate"
              :close-on-content-click="false"
              :nudge-top="20"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="toDate"
                  label="Sampai Tanggal"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                @input="menuToDate = false"
                :min="reportData.id === '5' ? getStartDate : null"
                :max="reportData.id === '5' ? nowDate : null"
              ></v-date-picker>
            </v-menu>
            <div v-if="reportData.id === '4'">
              <v-autocomplete
                dense
                outlined
                v-model="driverSelect"
                :items="driverData"
                item-text="driver_name"
                item-value="driver_id"
                label="Nama Pengemudi"
              ></v-autocomplete>
            </div>
            <div v-if="reportData.id === '3' || reportData.id === '6'">
              <v-autocomplete
                dense
                outlined
                v-model="customerSelect"
                :items="customerData"
                item-text="customer_name"
                item-value="customer_id"
                label="Nama Pelanggan"
              ></v-autocomplete>
            </div>
            <div v-if="reportData.id === '2' || reportData.id === '5'">
              <v-autocomplete
                dense
                outlined
                v-model="productSelect"
                :items="productData"
                item-text="product_name"
                item-value="product_id"
                label="Nama Produk"
              ></v-autocomplete>
            </div>
            <div v-if="reportData.id === '5'">
              <v-autocomplete
                dense
                outlined
                v-model="opnameSelect"
                :items="opnameData"
                item-text="opname_type"
                item-value="opname_id"
                label="Keterangan"
              ></v-autocomplete>
            </div>
            <div v-if="reportData.id === '6'">
              <v-autocomplete
                dense
                outlined
                v-model="debtTypeSelect"
                :items="debtTypeData"
                item-text="name"
                item-value="id"
                label="Tipe"
              ></v-autocomplete>
              <v-autocomplete
                dense
                outlined
                v-model="debtStatusSelect"
                :items="debtStatusData"
                item-text="debt_status_name"
                item-value="debt_status_id"
                label="Status"
              ></v-autocomplete>
            </div>
            <div v-if="reportData.id === '7'">
              <v-autocomplete
                dense
                outlined
                v-model="operatorSelect"
                :items="operatorData"
                item-text="operator_name"
                item-value="id"
                label="Nama Operator"
              ></v-autocomplete>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn block color="primary" @click="printReport(reportData.id)"
            >Cetak Laporan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";

export default {
  components: {},
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      customerSelect: "",
      productSelect: "",
      driverSelect: "",
      reportData: "",
      opnameSelect: "",
      debtTypeSelect: "",
      debtStatusSelect: "",
      operatorSelect: "",
      nowDate: new Date().toISOString().slice(0, 10),
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      date: new Date(),
      menuFromDate: false,
      menuToDate: false,
      valid: true,
      reports: [
        {
          id: "1",
          title: "Laporan Daftar Penjualan",
          subtitle:
            "Laporan daftar penjualan adalah sebuah bentuk laporan untuk mengetahui daftar penjualan toko pada periode tertentu.",
          method: ""
        },
        {
          id: "2",
          title: "Laporan Penjualan Per Produk",
          subtitle:
            "Laporan penjualan per produk adalah sebuah bentuk laporan untuk mengetahui daftar penjualan toko pada periode tertentu yang dikelompokkan berdasarkan produk.",
          method: ""
        },
        {
          id: "3",
          title: "Laporan Penjualan Per Pelanggan",
          subtitle:
            "Laporan penjualan per pelanggan adalah sebuah bentuk laporan untuk mengetahui daftar penjualan toko pada periode tertentu yang dikelompokkan berdasarkan pelanggan.",
          method: ""
        },
        {
          id: "4",
          title: "Laporan Pengiriman Per Pengemudi",
          subtitle:
            "Laporan daftar pengiriman oleh pengemudi adalah laporan yang digunakan untuk melihat jumlah pengiriman oleh tiap driver per periode tertentu.",
          method: ""
        },
        {
          id: "5",
          title: "Laporan Stok Opname",
          subtitle:
            "Laporan stok opname adalah laporan yang berisi daftar pergerakan stok produk milik toko pada periode tertentu.",
          method: ""
        },
        {
          id: "6",
          title: "Laporan Daftar Hutang/ Piutang Toko",
          subtitle:
            "Laporan daftar Hutang/ Piutang adalah sebuah bentuk laporan untuk mengetahui daftar Hutang/ Piutang toko pada periode tertentu.",
          method: ""
        },
        {
          id: "7",
          title: "Laporan Kas Masuk dan Kas Keluar",
          subtitle:
            "Laporan Kas Masuk dan Kas Keluar adalah sebuah bentuk laporan untuk mengetahui pengeluaran dan pemasukan kas di Toko pada periode tertentu.",
          method: ""
        }
      ],
      opnameData: [
        {
          opname_id: -1,
          opname_type: "All"
        },
        {
          opname_id: 1,
          opname_type: "Opname"
        },
        {
          opname_id: 2,
          opname_type: "Penambahan"
        },
        {
          opname_id: 3,
          opname_type: "Pengurangan"
        }
      ],
      debtStatusData: [
        {
          debt_status_id: -1,
          debt_status_name: "All"
        },
        {
          debt_status_id: 0,
          debt_status_name: "Belum Lunas"
        },
        {
          debt_status_id: 1,
          debt_status_name: "Lunas"
        }
      ]
    };
  },

  mounted() {
    this.$store.dispatch("common/getAllCustomer");
    this.$store.dispatch("common/getAllProduct");
    this.$store.dispatch("common/getAllDriver");
    this.$store.dispatch("common/getAllDebtType");
    this.$store.dispatch("common/getAllOperatorList");
  },

  computed: {
    loadingStatus() {
      return this.$store.getters.getLoading;
    },
    customerData() {
      return this.$store.getters["common/getCustomer"];
    },
    productData() {
      return this.$store.getters["common/getProduct"];
    },
    driverData() {
      return this.$store.getters["common/getDriver"];
    },
    debtTypeData() {
      return this.$store.getters["common/getDebtType"];
    },
    operatorData() {
      return this.$store.getters["common/getOperator"];
    },
    getStartDate() {
      const endDate = new Date(
        this.date.getFullYear(),
        this.date.getMonth() - 2,
        24
      );
      return endDate.toISOString().slice(0, 10);
    }
  },

  methods: {
    showDialog(report) {
      this.dialog = true;
      this.reportData = report;

      const card = {
        1: () => null,
        2: () => {
          this.productSelect = this.productData[0].product_id;
        },
        3: () => {
          this.customerSelect = this.customerData[0].customer_id;
        },
        4: () => {
          this.driverSelect = this.driverData[0].driver_id;
        },
        5: () => {
          this.productSelect = this.productData[0].product_id;
          this.opnameSelect = this.opnameData[0].opname_id;
        },
        6: () => {
          this.customerSelect = this.customerData[0].customer_id;
          this.debtTypeSelect = this.debtTypeData[0].id;
          this.debtStatusSelect = this.debtStatusData[0].debt_status_id;
        },
        7: () => {
          this.operatorSelect = this.operatorData[0].id;
        },
        DEFAULT: () => null
      };

      return (card[report.id] || card.DEFAULT)();
    },
    printReport(id) {
      const printMethod = {
        1: () => this.printReportSalesList(),
        2: () => this.printReportSalesProduct(),
        3: () => this.printReportSalesCustomer(),
        4: () => this.printReportDriverDelivery(),
        5: () => this.printReportStockOpname(),
        6: () => this.printReportDebt(),
        7: () => this.printReportCashInOut(),
        DEFAULT: () => {
          this.$tostini({
            message: "Terjadi Kesalahan",
            type: "error"
          });
        }
      };

      return (printMethod[id] || printMethod.DEFAULT)();
    },

    printReportSalesList() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        file_name:
          "Daftar Transaksi " + this.fromDate + " - " + this.toDate + ".pdf"
      };

      this.$store
        .dispatch("report/printReportSalesList", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },

    printReportSalesProduct() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        product_id: this.productSelect,
        file_name:
          "Daftar Penjualan per Produk " +
          this.fromDate +
          " - " +
          this.toDate +
          ".pdf"
      };

      this.$store
        .dispatch("report/printReportSalesProduct", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },

    printReportSalesCustomer() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        customer_id: this.customerSelect,
        file_name:
          "Daftar Penjualan per Pelanggan " +
          this.fromDate +
          " - " +
          this.toDate +
          ".pdf"
      };

      this.$store
        .dispatch("report/printReportSalesCustomer", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },

    printReportDriverDelivery() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        driver_id: this.driverSelect,
        file_name:
          "Daftar Pengiriman Per Pengemudi " +
          this.fromDate +
          " - " +
          this.toDate +
          ".pdf"
      };

      this.$store
        .dispatch("report/printReportDriverDelivery", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },

    printReportStockOpname() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        product_id: this.productSelect,
        type: this.opnameSelect,
        file_name: "Stok Opname " + this.fromDate + " - " + this.toDate + ".pdf"
      };

      this.$store
        .dispatch("report/printReportStockOpname", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },

    printReportDebt() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        type: this.debtTypeSelect,
        customer_id: this.customerSelect,
        debt_status: this.debtStatusSelect,
        file_name:
          "Daftar Hutang/ Piutang " +
          this.fromDate +
          " - " +
          this.toDate +
          ".pdf"
      };

      this.$store
        .dispatch("report/printReportDebt", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },

    printReportCashInOut() {
      this.$root.$loaderModal.start("Downloading...");

      const payload = {
        from_date: this.fromDate,
        to_date: this.toDate,
        operator_id: this.operatorSelect,
        file_name:
          "Daftar Kas Masuk dan Kas Keluar " +
          this.fromDate +
          " - " +
          this.toDate +
          ".pdf"
      };

      this.$store
        .dispatch("report/printReportCashInOut", payload)
        .then(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "success"
          });
          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: this.$store.state.message,
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    }
  }
};
</script>

<style>
.list:hover {
  background-color: #dcdcdc;
  border-radius: 5px;
}
</style>
